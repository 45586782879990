



















import { Component, Vue } from "vue-property-decorator";
import MyForm from "@/components/MyForm.vue";
import { Toast } from "vant";
import Mixin from "@/js/mixins";

@Component({ components: { MyForm }, mixins: [Mixin] })
export default class ShopApply extends Vue {
  public id = ""; // 店铺id（shop_id）
  public form_list: MyForm.formList[] = [];
  public shop_image: MyForm.formList[] = [];
  public parent_info: Dictionary<any> = {};

  // 失去焦距时执行
  formBlur(item: MyForm.formList) {
    let _this = this;
    if (item.name === "parent_name" && item.value) {
      this.$api.request({
        loading: "",
        url: "common/shop/validation-parent",
        data: { parent_name: item.value },
        success(res) {
          if (!res.data) {
            Toast("推荐码或赛蜜编码错误");
            _this.parent_info = {};
          } else {
            _this.parent_info = res.data;
          }
        }
      });
    }
  }
  // 当form组件值改变时执行
  formChange(form_list: MyForm.formList[]) {
    let boolean1: boolean = true;
    let index1: number = -1;
    let boolean2: boolean = true;
    let index2: number = -1;
    form_list.forEach((item, index) => {
      if (item.name === "parent_name") {
        this.parent_info = {};
      }
      // 选择“合资”时才显示“合作对象”
      if (item.name === "manage_type" && item.value === "2") {
        boolean1 = false;
      }
      if (item.name === "teamwork_type") {
        index1 = index;
      }
      // “相关资料”选择“其他”时才显示“请注明”
      if (
        item.name === "relevant_type" &&
        item.value.split(",").indexOf("7") !== -1
      ) {
        boolean2 = false;
      }
      if (item.name === "relevant_note") {
        index2 = index;
      }
    });
    if (index1 !== -1) {
      form_list[index1].hidden = boolean1;
    }
    if (index2 !== -1) {
      form_list[index2].hidden = boolean2;
    }

    this.form_list = form_list;
  }
  imageChange(form_list: MyForm.formList[]) {
    this.shop_image = form_list;
    console.log(this.$refs.image)
  }
  // 获取form表单的数据
  getFormData(): Dictionary<string> {
    let form: Dictionary<string> = this.$api.getFormData([
      this.form_list,
      this.shop_image
    ]);
    form.shop_time = this.$api.date("Y-m-d", form.shop_time);
    this.form_list.forEach((item, index) => {
      if (!item.hidden) {
        if (item.name === "relevant_type") {
          // 不获取key值获取内容
          let value: string[] = [];
          item.value.split(",").forEach(it => {
            let range = item.range || {};
            value.push(range[it]);
          });
          form[item.name] = value.join(",");
        }
      }
    });
    return form;
  }
  // 提交表单
  submit() {
    let form: any = this.$refs.form;
    // let image: any = this.$refs.image;
    // console.log(this.getFormData());
    let boolean1 = form.ValidateAll();
    // let boolean2 = image.ValidateAll();
    if (boolean1) {
      this.submitRequest(this.getFormData());
    }
  }
  submitRequest(data: Dictionary<string>) {
    let _this = this;
    let url = "user/users/add-user-address";
    if (this.id) {
      url = "user/users/add-user-address";
      data.user_id = this.id;
    }
    this.$api.request({
      url,
      data,
      success(res) {
        // _this.$api.href("/shop/detail?id=" + (_this.id || res.data));
        Toast("地址完善成功");

        setTimeout(() => {
          window.history.go(-1);
        }, 1000);
      }
    });
  }

  // 获取店铺信息（填入input框）
  getShopDetails() {
    let _this = this;
    let form_list = this.form_list;
    let shop_image = this.shop_image;
    this.$api.request({
      url: "common/shop/shop-details",
      data: { shop_id: this.id },
      success(res) {
        let data = res.data;
        
        form_list.forEach((item, index) => {
          if (data[item.name]) {
            if (item.name === "town") {
              _this.$set(item, "prov", data.province);
              _this.$set(item, "city", data.city);
              _this.$set(item, "area", data.district);
              _this.$set(item, "town", data.town);
            }
            if (item.name === "shop_time") {
              data[item.name] = new Date(data[item.name]);
            }
            _this.$set(item, "value", data[item.name]);
          }
        });
        shop_image.forEach((item, index) => {
          if (data[item.name]) {
            _this.$set(item, "value", data[item.name]);
          }
        });
        // 值更变后，重新加载
        _this.$api.refreshForm([_this.$refs.form, _this.$refs.image]);
        console.log(_this,'zhehsi')
      }
    });
  }
  // 获取“投资方式”，“相关资料”的列表
  getStatusAll() {
    this.$api.getStatusAll(data => {
      this.form_list.forEach((item, index) => {
        if (item.name === "manage_type") {
          this.$set(item, "range", data.manage_type);
        }
        if (item.name === "relevant_type") {
          this.$set(item, "range", data.relevant_type);
        }
      });
    });
  }

  init() {
    this.getStatusAll();
    // 修改店铺信息
    this.id = String(this.$route.query.user_id || "");
    this.form_list = [
      // {
      //   name: "parent_name",
      //   value: "",
      //   label: "推荐码",
      //   type: "input",
      //   placeholder: "请输入推荐码或赛蜜编码",
      //   required: true
      // },
      {
        name: "realname",
        value: "",
        label: "联系人",
        type: "input",
        placeholder: "请输入收货人名字",
        required: true
      },
      {
        name: "mobile",
        value: "",
        label: "联系方式",
        type: "input",
        input_type: "number",
        placeholder: "请输入收货人手机号码",
        required: true,
      },
      {
        name: "town",
        value: "",
        label: "门店地址",
        type: "regionPicker",
        region_level: 4,
        prov: "",
        city: "",
        area: "",
        town: "",
        placeholder: "请选择门店地址",
        required: true
      },
      {
        name: "address",
        value: "",
        label: "门店详细地址",
        type: "input",
        input_type: "text",
        placeholder: "请输入门店详细地址",
        required: true
      },
      // {
      //   name: "manage_type",
      //   value: "0",
      //   label: "投资方式",
      //   type: "picker",
      //   range: { 0: "请输入", 1: "独资", 2: "合资" },
      //   required: true
      // },
      // {
      //   name: "teamwork_type",
      //   value: "",
      //   label: "合作对象",
      //   type: "input",
      //   input_type: "text",
      //   placeholder: "请选择合作对象",
      //   required: true,
      //   hidden: true
      // },
    ];
    this.shop_image = [
      {
        name: "door_imr",
        value: "",
        label: "店铺门头",
        type: "image",
        required: true,
        inline_width: 50
      },
      {
        name: "business_img",
        value: "",
        label: "营业执照",
        type: "image",
        required: true,
        inline_width: 50
      },
      {
        name: "door_decs",
        value: "",
        label: "店内图片(至少三张)",
        type: "image",
        required: true,
        validate_min: 3,
        validate_max: 6
      }
    ];
    this.$api.refreshForm([this.$refs.form, this.$refs.image]);

    if (this.id) {
      // this.getShopDetails();
    }
  }
}
